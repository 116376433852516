import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "source" ]

  connect() {
  }

	insertFlash(event) {
		let [data, status, xhr] = event.detail;
		document.getElementById('flashes-container').outerHTML = xhr.response
	}

}
