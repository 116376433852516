import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "selected", 'customObjectivesList', 'globalObjectivesFormField',
	'customObjectivesFormField', 'globalOptionsList' ]

	addObjectiveToMission(event) {
		// Hide Empty State on first Mission Selection
		this.removeEmptyState();

		// Add Newly Selected Custom Objective Element to Preview View
		let new_custom_objective = document.importNode(document.getElementById('new_custom_objective').content, true)
		let selected_objective_id = 'selected_' + event.target.id

		new_custom_objective.firstChild.id = selected_objective_id
		new_custom_objective.firstChild.children[1].setAttribute('for', selected_objective_id)
		new_custom_objective.firstChild.children[1].lastChild.innerText = event.target.value

		this.customObjectivesListTarget.appendChild(new_custom_objective)

		if (event.target.id == 'create_new_objective_option') {
			this.addObjectiveToCustomObjectivesField();
		}
		else {
			this.addObjectiveToGlobalObjectivesField();
		}

		// Hide Newly Selected Custom Objective from the Search Options

		// Doing this manually rather than using a Stimulus Target due to scoping.
		// This takes place in a modal, but is triggered from outside of it.
		// SPA FTW...
		let selected_option = this.globalOptionsListTarget.querySelector('#' + event.target.id)
		selected_option.classList.add('hide')

		// Display Custom Objectives List if at least 1 Custom Objective Exists
		this.toggleCustomObjectivesList();
	}

	removeObjectiveFromMission() {
		let objectiveToRemove = event.currentTarget.parentElement

		if (objectiveToRemove.id.substr(9).includes('global')) {
			this.globalOptionsListTarget.querySelector('#' + objectiveToRemove.id.substr(9)).classList.remove('hide')
			this.removeObjectiveFromGlobalObjectivesField();
		}
		else {
			let custom_objectives_form_field = this.customObjectivesFormFieldTarget
			let custom_objectives_field_value = JSON.parse(custom_objectives_form_field.value)

			custom_objectives_field_value.descriptions = custom_objectives_field_value.descriptions.filter(i => i !== objectiveToRemove.innerText)
			custom_objectives_form_field.value = JSON.stringify(custom_objectives_field_value)
		}

		objectiveToRemove.remove();
		this.toggleCustomObjectivesList();
	}

	addObjectiveToGlobalObjectivesField() {
		let global_objectives_form_field = this.globalObjectivesFormFieldTarget
		let global_objectives_field_value = JSON.parse(global_objectives_form_field.value)
		let global_objective_id = event.target.id.replace( /^\D+/g, '')

		global_objectives_field_value.ids.push(global_objective_id)
		global_objectives_form_field.value = JSON.stringify(global_objectives_field_value)
	}

	// REFACTOR THIS AND ABOVE METHOD INTO SAME
	removeObjectiveFromGlobalObjectivesField() {
		let global_objectives_form_field = this.globalObjectivesFormFieldTarget
		let global_objectives_field_value = JSON.parse(global_objectives_form_field.value)
		let global_objective_id = event.currentTarget.parentElement.id.replace( /^\D+/g, '')

		global_objectives_field_value.ids = global_objectives_field_value.ids.filter(i => i !== global_objective_id)
		global_objectives_form_field.value = JSON.stringify(global_objectives_field_value)
	}

	addObjectiveToCustomObjectivesField() {
		let custom_objectives_form_field = this.customObjectivesFormFieldTarget
		let custom_objectives_field_value = JSON.parse(custom_objectives_form_field.value)

		custom_objectives_field_value.descriptions.push(event.target.value)
		custom_objectives_form_field.value = JSON.stringify(custom_objectives_field_value)
	}

	removeEmptyState(event) {
		// Hide Empty State Container
		let preview_empty_state = document.getElementById('preview_empty_state')
		preview_empty_state.classList.add('hide')

		// Unhide Objectives Preview Container & Mission Title Text Input
		let new_objectives_preview = document.getElementById('new_objectives_preview')
		new_objectives_preview.classList.remove('hide')

		let mission_title_input = document.getElementById('mission_title')
		mission_title_input.classList.remove('hide')
	}

	toggleCustomObjectivesList(event) {
		// Show or hide Custom Objectives List if at least 1 Custom Objective is added
		let custom_objectives_list = this.customObjectivesListTarget

		if (custom_objectives_list.lastChild.tagName == 'DIV') {
			custom_objectives_list.classList.remove('hide')
		} else {
			custom_objectives_list.classList.add('hide')
		}
	}

}