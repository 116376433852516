import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "source", "viewable", "createable", "editable" ]

	toggleCreateView(event) {
		this.toggleViewElementVisibility();
		this.toggleCreateElementVisibility();
	}

	toggleEditView(event) {
		this.toggleViewElementVisibility();
		this.toggleEditElementVisibility();
	}

	toggleViewElementVisibility() {
		this.viewableTargets.forEach((el, i) => {
			el.classList.toggle("hide")
		})
	}

	toggleCreateElementVisibility() {
		this.createableTargets.forEach((el, i) => {
			el.classList.toggle("hide")
		})
	}

	toggleEditElementVisibility() {
		this.editableTargets.forEach((el, i) => {
			el.classList.toggle("hide")
		})
	}

}
