import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "source", "toggable", "checkboxes" ]

	toggleCard(event) {
		// Height assignment hack since transitions don't work off of height: auto or 100%
		if (this.checkboxesTarget.classList.contains('hide')) {
			this.checkboxesTarget.classList.toggle('hide');
			this.toggableTarget.style.height = (this.checkboxesTarget.scrollHeight + 120 + "px")
		} else {
			this.toggableTarget.style.height = this.toggableTarget.children[0].scrollHeight + "px"

      // Hide expanded content within card, after delay so
      // the user doesn't see it disappearing from the DOM.

      // "this" loses it's context within SetTimeout, so we
      // create a var of the Target outside of the Timeout Function
      let contentContainer = this.checkboxesTarget

      setTimeout(function() {
        contentContainer.classList.toggle('hide')
      }, 1000);
		}

		this.toggableTarget.classList.toggle('closed');
		this.toggableTarget.classList.toggle('opened');
	}
}