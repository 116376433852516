import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'shareNewResourceModal', 'modalForm', 'hiddenDropdownValue' ]

  toggleShareResourceModal(event) {
    this.shareNewResourceModalTarget.classList.toggle('opacity-0');
    this.shareNewResourceModalTarget.classList.toggle('pointer-events-none');
  }

  clearFormInputs(event) {
    this.modalFormTargets.forEach((form, i) => {
      form.reset();
    });
    // Clear Hidden ID Fields from Dropdowns
    this.hiddenDropdownValueTargets.forEach((hiddenField, i) => {
      hiddenField.value = null
    });
  }

}