import $ from 'jquery';
import Foundation from 'foundation-sites';
import Reveal from 'foundation-sites';
import './application.scss';
import LocalTime from "local-time";

// const context = require.context("stylesheets", true, /\.scss$/)

window.jQuery = $;
window.$ = $;
LocalTime.start();

$(document).on('turbolinks:load', function() {
	$(document).foundation();
});