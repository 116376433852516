import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'dropdownInput', 'resultsList', 'selectable', 'existingRecordField', 'newRecordField' ]

  openResultsList(event) {
    this.resultsListTarget.classList.remove('hidden')
  }

  closeResultsList(event) {
    if(event.relatedTarget != this.resultsListTarget) {
      this.resultsListTarget.classList.add('hidden')
    }
  }

  filterResourcesByText(event) {
    let lowerCaseFilterTerms = this.dropdownInputTarget.value.toLowerCase()

    this.selectableTargets.forEach((el, i) => {
      let filterableKeys = [
        el.dataset.title,
        el.dataset.url
        ].join(" ")

      el.classList.toggle('hidden', !filterableKeys.includes(lowerCaseFilterTerms))
    })
  }

  selectDropdownResult(event) {
    let selected = (event.target.tagName === 'LI' ? event.target : event.target.closest('li'))

    this.existingRecordFieldTarget.value = selected.dataset.id;
    this.dropdownInputTarget.value = selected.dataset.title;
    this.resultsListTarget.classList.add('hidden');
    this.clearNewRecordFields();
  }

  clearExistingRecordFields(event) {
    this.dropdownInputTarget.value = null;

    this.existingRecordFieldTargets.forEach((existingRecordField, i) => {
      existingRecordField.value = null
    })
  }

  clearNewRecordFields(event) {
    this.newRecordFieldTargets.forEach((newRecordField, i) => {
      newRecordField.value = null
    })
  }

}