import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "selected", 'selectable', 'previewable', 'updateMissionForm' ]

	previewMissionTemplate(event) {
		// Hide Empty State on first Mission Selection
		this.removeEmptyState();

		// Update Mission Title Input Text to Selected Mission's
		let mission_title_input = document.getElementById('mission_title')
		mission_title_input.value = event.target.innerText;

		// Update Mission Form Field Value to Selected Mission's Text
		let mission_title_form_field = document.getElementsByClassName('new_mission_title')[0]
		mission_title_form_field.value = event.target.innerText;

		// Unhide the Selected Mission's Objectives List
		let selected_mission = (event.target.id + "_preview");
		let template_id_form_field = document.getElementById('student_mission_template_id');

		// Remove the hide CSS class when Mission Template is selected
		this.previewableTargets.forEach((el, i) => {
			el.id === selected_mission ? el.classList.remove("hide") : el.classList.add("hide")
		})

		// Update hidden template_id field in form on select -- "mission_1" becomes "1"
		template_id_form_field.value = event.target.id.replace( /^\D+/g, '');
	}

	updateTitleFormFieldValue(event){
		let mission_title_form_field = document.getElementsByClassName('new_mission_title')[0]
		mission_title_form_field.value = event.target.value;
	}

	toggleMissionTitleDisable(event) {
		let mission_title_input = document.getElementById('mission_title')

		mission_title_input.disabled ? mission_title_input.disabled = false : mission_title_input.disabled = true
	}

	removeEmptyState(event) {
		// Hide Empty State Container
		let preview_empty_state = document.getElementById('preview_empty_state')
		preview_empty_state.classList.add('hide')

		// Unhide Objectives Preview Container & Mission Title Text Input
		let new_objectives_preview = document.getElementById('new_objectives_preview')
		new_objectives_preview.classList.remove('hide')

		let mission_title_input = document.getElementById('mission_title')
		mission_title_input.classList.remove('hide')
	}

	displaySelectedMissionForEdit(event){
		// Replace Empty Mission Title Field with Title of Mission being Updated
		let updating_mission_title = document.getElementById('updating_mission_title')
		updating_mission_title.value = this.selectableTarget.querySelector('#student_mission_title').value

		// Transform Selected Mission into Selector that Matches list in Modal
		let mission_being_updated = document.getElementById("updating_" + this.selectableTarget.id);

		// Doing this manually rather than using a Stimulus Target due to scoping.
		// This takes place in a modal, but is triggered from outside of it.
		// SPA FTW...
		let all_existing_objective_lists = Array.from(document.getElementsByClassName('existing_objectives'))

		// Unhide the Selected Mission's Objectives List
		all_existing_objective_lists.forEach((el, i) => {
			el.id === mission_being_updated.id ? el.classList.remove('hide') : el.classList.add('hide')
		})

		// Update Form Submission Params to this Mission
		let update_form = document.getElementById('update_mission_form')
		let current_path = new URL(update_form.action)

		let last_word_of_url = current_path.pathname.split('/').pop();
		let mission_id_being_updated = this.selectableTarget.id.replace( /^\D+/g, '')

		// Add a Mission ID to the URL if one isn't already there.
		if (last_word_of_url === "missions") {
			let mission_path = new URL(current_path).pathname + '/' + mission_id_being_updated
			update_form.action = mission_path
		}

		// Change existing Mission ID in URL when the Mission being Updated Modal has changed.
		// A URL like "/missions/54" becomes "/missions/54/55" when you open and close the
		// same modal multiple times in a row without this function.
		if ( !isNaN(last_word_of_url) && last_word_of_url != mission_id_being_updated ) {
			update_form.action = current_path.pathname.split('/').slice(0, -1).join('/') + '/' + mission_id_being_updated;
		}
	}

}