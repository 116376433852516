import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "source", "resultsList", "formIdField", "filterable", "selected" ]

  initialize() {
  	this.filterSelectedInterestsOnLoad();
  }

	studentsByText(event) {
		let lowerCaseFilterTerms = this.sourceTarget.value.toLowerCase()

		this.filterableTargets.forEach((el, i) => {
			let filterableKeys = [
				el.dataset.fullName,
				el.dataset.gradeNumber,
				el.dataset.graduationYear,
				el.dataset.schoolName
				].join(" ")

			el.classList.toggle("hide", !filterableKeys.includes(lowerCaseFilterTerms))
		})
	}

	missionsByText(event) {
		let lowerCaseFilterTerms = this.sourceTarget.value.toLowerCase()

		this.filterableTargets.forEach((el, i) => {
			let filterableKeys = [
				el.value
				].join(" ")

			el.classList.toggle("hide", !filterableKeys.includes(lowerCaseFilterTerms))
		})
	}

	objectivesByText(event) {
		let lowerCaseFilterTerms = this.sourceTarget.value.toLowerCase()
		let allGlobalObjectives = this.filterableTargets.map((el, i) => {return el.innerText})

		this.filterableTargets.forEach((el, i) => {
			let filterableKeys = [
				el.value
				].join(" ")

			el.classList.toggle("hide", !filterableKeys.includes(lowerCaseFilterTerms))
		})

		if (lowerCaseFilterTerms.length > 1 && !allGlobalObjectives.includes(lowerCaseFilterTerms)) {
			this.createOrUpdateNewObjectiveOption();
		} else if (document.getElementById('create_new_objective_option')) {
			// Remove Option Element when exact match
			document.getElementById('create_new_objective_option').remove();
		}
	}

	createOrUpdateNewObjectiveOption(){
		// Update Existing Option if Exists
		if ( document.getElementById('create_new_objective_option') ) {
			document.getElementById('create_new_objective_option').classList.remove('hide') // This currently gets set after a tag is selected.
			document.getElementById('create_new_objective_option').value = this.sourceTarget.value
			document.getElementById('create_new_objective_option').text = `Create New Objective: ${this.sourceTarget.value}`
		}
		// Else Insert Option Element in Dom
		else {
			let html =	`<option id="create_new_objective_option" data-action="click->objectives#addObjectiveToMission"
										value="${this.sourceTarget.value}">Create Custom Objective: ${this.sourceTarget.value}
									</option>`

			// This is brittle and will break if you separate the
			// search input and result DOM elements.
			event.target.nextElementSibling.innerHTML += html;
		}
	}

	interestsByText(event) {
		let lowerCaseFilterTerms = this.sourceTarget.value.toLowerCase()
		let allExistingTagValues = this.filterableTargets.map((el, i) => {return el.innerText})

		// TODO: How do I extract allExistingTagValues and freeze it so that it doesn't run on every event fire?
		// TODO: I think that adding a tag is hiding the class and so it doesn't appear again.

		this.filterableTargets.forEach((el, i) => {
			let filterableKeys = [
				el.text
				].join(" ")

			el.classList.toggle("hide", !filterableKeys.includes(lowerCaseFilterTerms))
		})

		if (lowerCaseFilterTerms.length > 1 && !allExistingTagValues.includes(lowerCaseFilterTerms)) {
			this.createOrUpdateNewInterestOption();
		} else if (document.getElementById('create_new_interest_option')) {
			// Remove Option Element when exact match
			document.getElementById('create_new_interest_option').remove();
		}
	}

	createOrUpdateNewInterestOption(){
		// Update Existing Option if Exists
		if ( document.getElementById('create_new_interest_option') ) {
			document.getElementById('create_new_interest_option').classList.remove('hide') // This currently gets set after a tag is selected.
			document.getElementById('create_new_interest_option').value = this.sourceTarget.value
			document.getElementById('create_new_interest_option').text = `Create New Interest: ${this.sourceTarget.value}`
		}
		// Else Insert Option Element in Dom
		else {
			let html =	`<option data-controller="interests" id="create_new_interest_option" data-action="click->interests#addInterest"
										value="${this.sourceTarget.value}">Create New Interest: ${this.sourceTarget.value}
									</option>`

			document.getElementById('interest_list').innerHTML += html;
		}
	}

	filterSelectedInterestsOnLoad(){
  	// Filter out Tags on page load
  	let alreadySelectedTags = this.selectedTargets.map((el, i) => {return el.innerText})

    this.filterableTargets.forEach((el, i) => {
    	el.classList.toggle("hide", alreadySelectedTags.includes(el.value) )
    })
	}

}

// Current issues with interests filtering.
// Tags are filtered on load, however their class is toggled by text search
// and so they will appear again if:
// A) you remove all search text and look through the list; or
// B) you search for the specific tag which is already selected for that student

// 1) Filter based on Input

// Option 1
// Load all tags, hide selected tags in bottom list if tag is already selected.
// Remove tag from hidden input value when (x) is clicked + Unhide tag div.

// 2) If input > 0 && !== option then show create row
// 3) When you click create row then append array object to input text area value
// DONE 4) Style each value with tagging bubble
// 5) Add an expand/collapse to view if tags > (x)