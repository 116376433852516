import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "textSubmit", "infiniteScrollAnchor" ]

	connect() {
		this.initializeInfiniteScrollObserver();
	}

	initialize() {
		let textThread = document.getElementById("text-thread")
		textThread.scrollTop = textThread.scrollHeight;
	}

	initializeInfiniteScrollObserver() {
		let observerOptions = {
			root: this.infiniteScrollAnchorTarget.parentElement,
			rootMargin: "500px 0px 0px 0px",
			threshold: 1
		}

		let infiniteScrolleObserver = new IntersectionObserver(this.trackScroll, observerOptions)
		infiniteScrolleObserver.observe(this.infiniteScrollAnchorTarget)
	}

	trackScroll(entries) {
		let studentId = document.getElementById('communications-channel').dataset.threadId
		let anchorTarget = document.getElementById('infinite-scroll-anchor');
		let currentPage = parseInt(anchorTarget.dataset.pageNumber);
		let nextPage = currentPage + 1;

		entries.forEach(function(entry) {
			const scrollingTowardTopOfList = entry.boundingClientRect.y >= entry.rootBounds.y;

			if (scrollingTowardTopOfList) {
				addNextPage().catch(error => console.error(`${error}`))
			}

			async function addNextPage () {
				let resp = await fetch(`/mentors/students/${studentId}/messages/?page=${nextPage}`)
				if (!resp.ok) throw resp.statusText;

				let html = await resp.text()
				await anchorTarget.insertAdjacentHTML('afterend', html)
				await anchorTarget.dataset.pageNumber++
			}
		})
	}

	sendAndClearText(event) {
		if (event.keyCode == 13 && !event.shiftKey) {
			event.preventDefault();

			this.textSubmitTarget.click();
			document.getElementById("sms_log_body").value = "";
		}
	}

	// receive()

	// send()
}