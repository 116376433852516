import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "selected" ]

	addInterest(event) {
		let updated_interests_list = document.getElementById('updated_interests_list')
		updated_interests_list.value = updated_interests_list.value + ', ' + event.target.value
		event.target.classList.toggle("hide")

		// Render and add interest tag HTML here
		// TODO: Find a way to refactor this to use the Partial Rendering below.
		// let html = ApplicationController.render template: 'mentors/student/interests/_interest', locals: { interest: @interest }
		let html = `<div class="interest_tag" data-target="filter.selected interests.selected">
									<span>${event.target.value}</span>
									<a data-controller="interests" data-action="click->interests#removeInterest">
										<i class="fas fa-times-circle fa-lg"></i>
									</a>
								</div>`

		document.getElementById('selected_tags_list').innerHTML += html;
	}

	removeInterest(event) {
		// Remove the tag UI element.
		event.currentTarget.parentElement.remove();

		// Remove the tag from the hidden form input.
		let updated_interests_string = this.selectedTargets.map ((el, i) => {return el.innerText})
		let updated_interests_list = document.getElementById('updated_interests_list')
		updated_interests_list.value = updated_interests_string
		updated_interests_list.innerText = updated_interests_string
	}

}