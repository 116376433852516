import { Controller } from 'stimulus';
import consumer from 'channels/consumer';

let currentChannel

export default class extends Controller {
  static targets = ['thread']

  connect() {
    this.initChannel();
  }

  initChannel() {
    let studentId = (this.threadTarget) ? this.threadTarget.dataset.threadId : 'master';

    if (currentChannel) currentChannel.unsubscribe();

    currentChannel = consumer.subscriptions.create({ channel: 'DashboardChannel', student_id: studentId }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if ("flash" in data.update_event) this.insert_flashes(data.update_event.flash_html);

        switch(data.update_event.model) {
          case 'SmsLog':
            this.insertNewText(data.update_event)
            break;
          case 'EmailLog':
            this.handleEmailUIUpdate(data.update_event)
            break;
          case 'StudentMission':
            this.handleStudentMissionUIUpdate(data.update_event)
            break;
        }
      },

      insert_flashes(flash_html) {
        document.getElementById('flashes-container').outerHTML = flash_html
      },

      handleStudentMissionUIUpdate(update_event) {
        switch(update_event.action) {
          case 'accomplish_mission':
            this.moveMissionToAccomplishedList(update_event)
            break;
          case 'reopen_mission':
            this.reopenMission(update_event)
            break;
        }
      },

      moveMissionToAccomplishedList(update_event) {
        let active = document.getElementById('active_missions_list')
        let accomplished = document.getElementById('accomplished_missions_list')

        document.getElementById(`student_mission_${update_event.model_instance.id}`).remove();
        document.getElementById('active_missions_tab').innerText = `Active (${active.children.length})`

        accomplished.insertAdjacentHTML("afterbegin", update_event.rendered_html)
        document.getElementById('accomplished_missions_tab').innerText = `Accomplished(${accomplished.children.length})`
      },

      reopenMission(update_event) {
        let active = document.getElementById('active_missions_list')
        let accomplished = document.getElementById('accomplished_missions_list')

        document.getElementById(`student_mission_${update_event.model_instance.id}`).remove();
        document.getElementById('accomplished_missions_tab').innerText = `Accomplished(${accomplished.children.length})`

        active.insertAdjacentHTML("afterbegin", update_event.rendered_html)
        document.getElementById('active_missions_tab').innerText = `Active (${active.children.length})`
      },

      insertNewText(update_event) {
        let textThread = document.getElementById("text-thread")
        document.getElementById("text-thread").insertAdjacentHTML("beforeend", update_event.rendered_html)

        // MAKE SURE IT DOESN'T SCROLL DOWN IF PERSON IS SCROLLED UP
        textThread.scrollTop = textThread.scrollHeight;
      },

      handleEmailUIUpdate(update_event) {
        // Need to write this in the future after refactoring the HTML data attributes
        // insertNewThread
        // appendToExistingVisibleThread -- clean this up!

        // let EmailThreadSummary = document.body.querySelector(`[data-thread-id=${update_event.model_instance.thread_id}]`)
        // let EmailThreadInView = document.querySelectorAll(`.widget-messages__content__item--email-child`)

        //if (EmailThreadInView) {
          //let LastEmailInThread = EmailThreadInView()[EmailThreadInView.length-1]
          //console.log(LastEmailInThread)
        //}
      },

    });
  }
}